import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import textFormatService from "../../../../../../services/utility/textFormatService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface CollateralDetailsFields {
  category: string;
  type: string;
  description: string;
  value: Number;
  propSize: string;
  propLocation: string;
  ownershipStructure: string;
  serialNo: string;
  assessedCondition: string;
}

class CollateralDetailsForm extends BaseFormComponent<CollateralDetailsFields> { }

interface CollateralDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface DependentSelectItems {
  value: any;
  inputData: ISelectItems[];
}

interface CollateralDetailsState {
  collaterals: CollateralDetailsFields[];
  ownUpdate: boolean;
  propertyCategory: boolean;
}

class CollateralDetails extends Component<
  CollateralDetailsProps,
  IBaseFormState & CollateralDetailsState
> {
  constructor(props: CollateralDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      collaterals: [],
      ownUpdate: false,
      propertyCategory: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: CollateralDetailsProps,
    prevState: CollateralDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.values.collaterals)) {
      return null;
    }
    return {
      collaterals: nextProps.values.collaterals
    };
  }

  onFormSubmit(fields: CollateralDetailsFields, values:any, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: {}, ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, values, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: CollateralDetailsFields,
    loan:any,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.collaterals = this.state.collaterals
      loan.collaterals = this.state.collaterals

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Collateral Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  handleOnCollateralCategoryChange = async (category: string) => {
    if (category === "Property") {
      await this.setState({ propertyCategory: !this.state.propertyCategory, ownUpdate: true });
    }
    else {
      if (this.state.propertyCategory) {
        await this.setState({ propertyCategory: !this.state.propertyCategory, ownUpdate: true });
      }
      else {
        await this.setState({ propertyCategory: this.state.propertyCategory, ownUpdate: true });
      }
    }
  };

  handleOnAddCollateral = (item: CollateralDetailsFields, onReloadFieldsCB: any) => {
    this.setState(
      { errors: hybridAppraisalValidationService.validateAddCollateralForm(item, this.state.propertyCategory, this.props.mandatoryFields), ownUpdate: true },
      () => {
        if (Object.keys(this.state.errors).length === 0) {
          const collaterals = [...this.state.collaterals];
          collaterals.push(item);
          this.setState({ collaterals, ownUpdate: true });
          this.resetFields();
          onReloadFieldsCB();
        }
      }
    );
    this.setState({ validated: true, ownUpdate: true });
  };

  handleOnRemoveCollateral = (item: CollateralDetailsFields) => {
    const collaterals = [...this.state.collaterals];
    if (collaterals.includes(item)) {
      collaterals.splice(
        collaterals.indexOf(item), 1
      );
    }
    this.setState({ collaterals, ownUpdate: true });
  };

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true, propertyCategory: false });
  }

  render() {
    const { values } = this.props;
    const { errors, validated, collaterals } = this.state;

    let collateralCategoryInputData: ISelectItems[] = [
      "Cash",
      "Property",
      "Insurance",
      "Asset",
      "Others"
    ].map(item => {
      return { name: item, value: item }
    });

    let assessedConditionInputData: ISelectItems[] = [
      "Good",
      "Fair",
      "Poor",
    ].map(item => {
      return { name: item, value: item }
    });

    let collateralTypeInputData: DependentSelectItems[] = [
      { value: "Cash", inputData: ["Fixed Deposit", "Tbills", "Shares", "Others"].map(item => { return { name: item, value: item } }) },
      { value: "Property", inputData: ["Residential space", "Non-residential space", "Production space", "Land"].map(item => { return { name: item, value: item } }) },
      { value: "Insurance", inputData: ["Credit Life Insurance", "Key Man Insurance", "Fire and Special Peril", "Good in Transit"].map(item => { return { name: item, value: item } }) },
      { value: "Asset", inputData: ["Vehicle", "Landed properties", "Stocks", "Cheques", "Other Assets"].map(item => { return { name: item, value: item } }) },
      { value: "Others", inputData: ["Others"].map(item => { return { name: item, value: item } }) },
    ]

    return (
      <CollateralDetailsForm
        initialValues={{
          category: "",
          type: "",
          description: "",
          value: 0,
          propLocation: "",
          propSize: "",
          ownershipStructure: "",
          serialNo: "",
          assessedCondition: ""
        }}
        FormComponent={({
          fields: {
            category,
            type,
            description,
            value,
            propLocation,
            propSize,
            ownershipStructure,
            serialNo,
            assessedCondition
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-row">
                <SelectInputComponent
                  id="category"
                  name="collateralCategory"
                  divClass={6}
                  value={category}
                  items={collateralCategoryInputData}
                  required={this.getRequiredFields("collateralCategory")}
                  validated={validated}
                  errors={errors}
                  onChange={async (id, value) => {
                    await onChange(id, value);
                    await onChange('type', "");
                    await this.handleOnCollateralCategoryChange(value)
                  }}
                />
                <SelectInputComponent
                  id="type"
                  name="collateralType"
                  divClass={6}
                  value={type}
                  items={
                    collateralTypeInputData.filter(data => data.value == category)[0] ?
                      collateralTypeInputData.filter(data => data.value == category)[0].inputData :
                      []
                  }
                  required={this.getRequiredFields("collateralType")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Collateral Description"
                  id="description"
                  name="description"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={description}
                  required={this.getRequiredFields("collateralDescription")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Collateral Value"
                  id="value"
                  name="value"
                  type="number"
                  placeholder=""
                  divClass={6}
                  value={value.toString()}
                  required={this.getRequiredFields("collateralValue")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Property Size"
                  id="propSize"
                  name="propSize"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={propSize}
                  required={this.getRequiredFields("propertySize")}
                  validated={validated}
                  errors={errors}
                  disabled={!this.state.propertyCategory}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Property Location"
                  id="propLocation"
                  name="propLocation"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={propLocation}
                  required={this.getRequiredFields("propertyLocation")}
                  validated={validated}
                  errors={errors}
                  disabled={!this.state.propertyCategory}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Ownership Structure"
                  id="ownershipStructure"
                  name="ownershipStructure"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={ownershipStructure}
                  required={this.getRequiredFields("ownershipStructure")}
                  validated={validated}
                  errors={errors}
                  disabled={!this.state.propertyCategory}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Serial Registration Number"
                  id="serialNo"
                  name="serialNo"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={serialNo}
                  required={this.getRequiredFields("serialRegistrationNumber")}
                  validated={validated}
                  errors={errors}
                  disabled={!this.state.propertyCategory}
                  onChange={onChange}
                />
                <SelectInputComponent
                  // label="Choose Account"
                  id="assessedCondition"
                  name="assessedCondition"
                  divClass={6}
                  value={assessedCondition}
                  items={assessedConditionInputData}
                  required={this.getRequiredFields("assessedCondition")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
              </div>

              {/* Add Collateral */}
              <div className="form-row">
                <div className="my-1 mx-2">
                  {(getCurrentUsersRoles().includes("updatedataentry") && (
                    <button
                      className="btn btn-sm btn-outline-primary my-4"
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() =>
                        this.handleOnAddCollateral(
                          {
                            category,
                            type,
                            description,
                            value,
                            propLocation,
                            propSize,
                            ownershipStructure,
                            serialNo,
                            assessedCondition
                          },
                          onReloadFields
                        )
                      }
                    >
                      <React.Fragment>
                        <i className="far fa-save fa-lg mr-2" />{" "}
                        Add
                      </React.Fragment>
                    </button>
                  ))}
                </div>
              </div>

              {/* Collateral Table */}
              <div className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Collateral Catgory</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Collateral Type</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Collateral Description</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Collateral Value</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Property Size</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Property Location</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Ownership Structure</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Serial Registration Number</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Assessed Condition</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {collaterals.map((item) => (
                        <tr key={collaterals.indexOf(item)}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {collaterals.indexOf(item) + 1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.type}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.description}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {textFormatService.formatCurrency(Number(item.value), 2)}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category === "Property" ? item.propSize : "NA"}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category === "Property" ? item.propLocation : "NA"}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category === "Property" ? item.ownershipStructure : "NA"}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category === "Property" ? item.serialNo : "NA"}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category === "Property" ? item.assessedCondition : "NA"}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            <i
                              className="far fa-trash-alt ml-5 red-text fa-lg"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.handleOnRemoveCollateral(
                                  item
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Save Collateral */}
              <div className="card-body float-right">
                {(getCurrentUsersRoles().includes("updatedataentry") && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={this.state.submitting}
                    onClick={(e) => {
                      onHandleSubmit(
                        e,
                        ActionTypes.SAVE,
                        "Collateral Details",
                        () => {
                          this.onFormSubmit(
                            {
                              category,
                              type,
                              description,
                              value,
                              propLocation,
                              propSize,
                              ownershipStructure,
                              serialNo,
                              assessedCondition
                            },
                            values,
                            onReloadFields
                          )
                        }
                      );
                    }}
                  >
                    {this.state.submitting === false ? (
                      <React.Fragment>
                        <i className="fas fa-lg fa-save mr-3" /> Save
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                        Please wait...
                      </React.Fragment>
                    )}
                  </button>
                ))}
              </div>

            </div>
          </div>
        )}
      ></CollateralDetailsForm>
    );
  }
}

export default CollateralDetails;
