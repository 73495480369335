import React, { Component, useContext } from "react";
import {
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import textFormatService from "../../../../services/utility/textFormatService";
import miscService from "../../../../services/hybridAppraisal/miscService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import Skeleton from "react-loading-skeleton";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface IfinancialAnalysisCheckProps {
    item: any;
}

interface IfinancialAnalysisCheckState {
    item: any;
    financialAnalysisResponse: any;
    ownUpdate: boolean;
    guarantorTurnOver: number;
    totalLoanObligation: number;
}

class FinancialAnalysisCheck extends Component<IfinancialAnalysisCheckProps, IfinancialAnalysisCheckState> {
    _isMounted: boolean;
    constructor(props: IfinancialAnalysisCheckProps) {
        super(props);
        this.state = {
            item: {},
            financialAnalysisResponse: [],
            ownUpdate: false,
            guarantorTurnOver: 0,
            totalLoanObligation: 0
        };
    }

    static getDerivedStateFromProps(nextProps: IfinancialAnalysisCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const BankStatement = this.props.item.appraisalActions?.filter(x => x.systemProcessID === Number(14) && x.effectiveStatus === "Valid")[0]?.processResponse;
        const loanRequest = await loanRequestService.retrieveById(this.props.item.id);
        const loanRequestDetail = loanRequest.data;
        let BankStatementDetail: any = []
        // console.log(this.props.item.appraisalActions?.filter(x => x.systemProcessID === Number(14))[0])
        try {
            BankStatementDetail = JSON.parse(BankStatement?.response);
        }
        catch {
            BankStatementDetail = []
        }
        // const BankStatement = await miscService.GetProcessedBankStatement(this.props.item.id);
        // const BankStatementDetail = BankStatement.data
        console.log(BankStatementDetail)

        const BankStatementDetailsList: any = [];
        var guarantorTurnOver = 0

        if (BankStatementDetail) {
            BankStatementDetail.forEach(x => {
                const BankStatementDetails: any = {};

                BankStatementDetails.accountNumber = x.AccountNumber
                BankStatementDetails.accountType = x.AccountType
                BankStatementDetails.bankName = x.BankName
                BankStatementDetails.status = x.Status
                BankStatementDetails.statusMessage = x.StatusMessage
                BankStatementDetails.channel = x.Channel
                BankStatementDetails.id = x.Id
                BankStatementDetails.institutionCode = x.InstitutionCode
                BankStatementDetails.loanRequestId = x.LoanRequestId
                BankStatementDetails.monthlySummary = x.MonthlySummary
                BankStatementDetails.analysis = x.Analysis
                BankStatementDetails.statementType = x.StatementType

                if (x.MonthlySummary?.length > 0) {
                    BankStatementDetails.TotalMonthsAnalysed = x.MonthlySummary?.length
                    BankStatementDetails.TotalCreditTurnover = x.MonthlySummary?.reduce((acc, current) => acc + current.TotalCredit, 0)
                    BankStatementDetails.TotalDebitTurnover = x.MonthlySummary?.reduce((acc, current) => acc + current.TotalDebit, 0)
                    BankStatementDetails.NetTurnoverForThePeriod = x.MonthlySummary?.reduce((acc, current) => acc + current.MonthlyNetTurnover, 0)
                    BankStatementDetails.TotalSalary = x.MonthlySummary.reduce((acc, current) => acc + current.SalaryAmount, 0)
                    BankStatementDetails.AverageMonthlyCreditTurnover = BankStatementDetails.TotalCreditTurnover / BankStatementDetails.TotalMonthsAnalysed
                    BankStatementDetails.AverageMonthlyDebitTurnover = BankStatementDetails.TotalDebitTurnover / BankStatementDetails.TotalMonthsAnalysed
                    BankStatementDetails.AverageMonthlyNetTurnover = BankStatementDetails.NetTurnoverForThePeriod / BankStatementDetails.TotalMonthsAnalysed
                    BankStatementDetails.AverageMonthlyBalance = x.MonthlySummary.reduce((acc, current) => acc + current.AverageMonthlyBalance, 0) / BankStatementDetails.TotalMonthsAnalysed
                    BankStatementDetails.AverageSalary = BankStatementDetails.TotalSalary / BankStatementDetails.TotalMonthsAnalysed
                }

                BankStatementDetailsList.push(BankStatementDetails)
            }
            );

            console.log(BankStatementDetailsList)
            BankStatementDetailsList.filter(x => x.statementType === "Guarantor")?.forEach(x => {
                guarantorTurnOver += x.NetTurnoverForThePeriod
            }
            )
        }

        var collectionSchedule: any[] = []
        try {
            collectionSchedule = JSON.parse(JSON.parse(loanRequestDetail.jsonData)).CollectionPaymentSchedules;
        }
        catch {
            collectionSchedule = []
        }
        var totalLoanObligation = 0;
        if (collectionSchedule?.length > 0) {
            // var len = collectionSchedule.length;
            collectionSchedule.forEach(x => {
                totalLoanObligation += Number(x.total);
            });
        }

        // const financialAnalysisResponse = JSON.parse(creditBureauResp?.response);
        // console.log(totalLoanObligation)
        if (this._isMounted) {
            this.setState({ financialAnalysisResponse: BankStatementDetailsList, ownUpdate: true, guarantorTurnOver: guarantorTurnOver, totalLoanObligation: totalLoanObligation });
        }
    }

    render() {
        const { item } = this.props;
        const { financialAnalysisResponse, totalLoanObligation, guarantorTurnOver } = this.state;
        console.log(financialAnalysisResponse)
        const spendCategorySummary = financialAnalysisResponse?.analysis?.SpendCategories;
        // [
        //     { SpendCategorySummary: "Airtime & Data", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "ATM & POS Withdrawals", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Transfers", Amount: "100000", Frequency: "1", Average: "100000" },
        //     { SpendCategorySummary: "USSD", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "International Transactions", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Utilities", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Travel", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Charges & Stamp Duty", Amount: "272.26", Frequency: "5", Average: "54.45" },
        //     { SpendCategorySummary: "Entertainment", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Gambling", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Insurance", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Rent", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Transportation", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Savings & Investments", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Online & Web Transactions", Amount: "34431.63", Frequency: "1", Average: "34431.63" },
        //     { SpendCategorySummary: "Embassy", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Hospitality & Food", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Health", Amount: "0", Frequency: "0", Average: "0" },
        //     { SpendCategorySummary: "Others", Amount: "614782.76", Frequency: "2", Average: "307391.38" }
        // ];
        const expenseSummary = financialAnalysisResponse?.analysis?.ExpenseSummaries;
        // [
        //     { ExpenseSummary: "Highest Expense", Amount: "489328.76", Date: "24/06/2022", Category: "Others" },
        //     { ExpenseSummary: "Lowest Expense", Amount: "8", Date: "29/05/2022", Category: "Charges & Stamp Duty" },
        //     { ExpenseSummary: "Total Expenses for the period analyzed", Amount: "749486.62", Date: "", Category: "" },
        //     { ExpenseSummary: "Average Monthly Expense", Amount: "83276.29", Date: "", Category: "" }
        // ];
        const recurringDebitTransactions = financialAnalysisResponse?.analysis?.RecurringDebits;
        // [
        //     { RecurringDebitTransactions: "NIP CR/DAVID MOSES MAIKASUWA/ABN", AverageAmount: "100000", Frequency: "2", AverageMonthlyFrequency: "0.67" },
        //     // { RecurringDebitTransactions: "Text here", AverageAmount: "Text here", Frequency: "Text here", AverageMonthlyFrequency: "Text here" },
        //     // { RecurringDebitTransactions: "Text here", AverageAmount: "Text here", Frequency: "Text here", AverageMonthlyFrequency: "Text here" },
        //     // { RecurringDebitTransactions: "Text here", AverageAmount: "Text here", Frequency: "Text here", AverageMonthlyFrequency: "Text here" }
        // ];
        const debitTransactionsAnalysis = financialAnalysisResponse?.analysis?.DebitTransactionsAnalysis;
        // [
        //     { DebitTransactionsAnalysis: "Less than N10,000.00", Percentage: "55", Frequency: "5", AverageMonthlyFrequency: "1.67" },
        //     { DebitTransactionsAnalysis: "Between N10,000.00 to N100,000.00", Percentage: "22", Frequency: "2", AverageMonthlyFrequency: "0.67" },
        //     { DebitTransactionsAnalysis: "Between N100,000.00 to N500,000.00", Percentage: "22", Frequency: "2", AverageMonthlyFrequency: "0.67" },
        //     { DebitTransactionsAnalysis: "Between N500,000.00 to N1,000,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" },
        //     { DebitTransactionsAnalysis: "Greater than N1,000,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" }
        // ];
        const recurringCreditTransactions = financialAnalysisResponse?.analysis?.RecurringCredits;
        // [
        //     { RecurringCreditTransactions: "VC SET OFF 2429828290-1008331963", AverageAmount: "69825.37", Frequency: "2", AverageMonthlyFrequency: "0.67" },
        // ];
        const creditTransactionsAnalysis = financialAnalysisResponse?.analysis?.CreditTransactionsAnalysis;
        // [
        //     { CreditTransactionsAnalysis: "Less than N10,000.00", Percentage: "20", Frequency: "1", AverageMonthlyFrequency: "0.33" },
        //     { CreditTransactionsAnalysis: "Between N10,000.00 to N100,000.00", Percentage: "60", Frequency: "3", AverageMonthlyFrequency: "0.67" },
        //     { CreditTransactionsAnalysis: "Between N100,000.00 to N500,000.00", Percentage: "20", Frequency: "1", AverageMonthlyFrequency: "0.33" },
        //     { CreditTransactionsAnalysis: "Between N500,000.00 to N1,000,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" },
        //     { CreditTransactionsAnalysis: "Greater than N1,000,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" }
        // ];
        const balanceAnalysis = financialAnalysisResponse?.analysis?.BalanceAnalysis;
        // [
        //     { BalanceAnalysis: "Less than N10,000.00", Percentage: "43", Frequency: "6", AverageMonthlyFrequency: "0.67" },
        //     { BalanceAnalysis: "Between N10,000.00 to N100,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" },
        //     { BalanceAnalysis: "Between N100,000.00 to N500,000.00", Percentage: "57", Frequency: "8", AverageMonthlyFrequency: "1" },
        //     { BalanceAnalysis: "Between N500,000.00 to N1,000,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" },
        //     { BalanceAnalysis: "Greater than N1,000,000.00", Percentage: "0", Frequency: "0", AverageMonthlyFrequency: "0" }
        // ];



        return (
            <div className="card">
                <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                        <Skeleton width={200} />
                    ) : (
                        <h6
                            className="card-title float-left"
                            style={{ marginBottom: "0px" }}
                        >
                            <b>Financial Analysis Review</b>
                        </h6>
                    )}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card no-b">
                            <div className="row">
                                {console.log(financialAnalysisResponse)}
                                {financialAnalysisResponse.length === 0 ? ("No Financial Record Found") : financialAnalysisResponse?.filter(y => y.statementType === "Customer")?.length < 1 ? ("") : financialAnalysisResponse?.filter(y => y.statementType === "Customer")?.map(z => (

                                    <div className="col-md-12">
                                        <div className="card">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Customer Financial Analysis Result</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-5">
                                                                            {/* <h6
                                                                            className="card-title"
                                                                            style={{ marginBottom: "0px", fontFamily: "sans-serif" }}
                                                                        >
                                                                            <b>Performance Summary</b>
                                                                        </h6>
                                                                        <br /> */}
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Bank Name"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={z?.bankName}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Account Number"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={z?.accountNumber}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Account Type"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={z?.accountType}
                                                                                />
                                                                                {z?.channel === "MBS" ? (
                                                                                    <DetailItem
                                                                                        label="Status Description"
                                                                                        labelSize={9}
                                                                                        valueSize={5}
                                                                                        value={z?.statusMessage}
                                                                                    />
                                                                                ) : ("")}
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>

                                        <div className="card">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Spend Pattern</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-6">
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Expense Summary</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Date</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Category</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {expenseSummary?.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.ExpenseSummary}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.Amount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Date}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Category}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <dl className="row mt-4">
                                                                                <DetailItem
                                                                                    label="Most Frequent Spend Category"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.MostFrequentSpendCategory}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Most Frequent Expense"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.MostFrequentExpense}
                                                                                />
                                                                                {/* <DetailItem
                                                                                    label="ATM Transaction Locations"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.ATMTransactionLocations}
                                                                                /> */}
                                                                            </dl>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Spend Category Summary</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {spendCategorySummary.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.SpendCategorySummary}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.Amount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.Average), 2)}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>
                                        <div className="card mt-3">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Transactional Pattern</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-12">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Total Transactions in the period"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.TotalTransactions}
                                                                                />
                                                                                {/* <DetailItem
                                                                                    label="Return Cheques"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={"0"}
                                                                                /> */}
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-md-6">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Debit Transactions (%)"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.DebitTransactionsPercentage}
                                                                                />
                                                                            </dl>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Recurring Debit Transactions</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {recurringDebitTransactions.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.RecurringDebitTransactions}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.AverageAmount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0 mt-3">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Debit Transactions' Analysis</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Percentage (%)</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {debitTransactionsAnalysis.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.DebitTransactionsAnalysis}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Percentage}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Credit Transactions (%)"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.CreditTransactionPercentage}
                                                                                />
                                                                            </dl>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Recurring Credit Transactions</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {recurringCreditTransactions.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.RecurringCreditTransactions}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.AverageAmount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0 mt-3">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Credit Transactions' Analysis</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Percentage (%)</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {creditTransactionsAnalysis.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.CreditTransactionsAnalysis}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Percentage}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>

                                        <div className="card mt-3">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Behavioural Pattern</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-6">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Average Account Activity within the period analyzed"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.AverageAccountActivity}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Account Sweep"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.AccountSweep}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Gambling Activity"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.GamblingActivity}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Total Monthly Loan Repayment"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.TotalMonthlyLoanRepayment}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Monthly Inflow to Outflow Rate"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.AverageMonthlyInflowToOutflow}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Total Inflow to Outflow rate"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.TotalInflowToOutflow}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Balance Consistency"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.BalanceConsistency}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Inflow Consistency"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.InflowConsistency}
                                                                                />
                                                                            </dl>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Balance Analysis</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Percentage</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {balanceAnalysis.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.BalanceAnalysis}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Percentage}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>

                                        {z?.status === "Completed" || _.isNull(z?.status) ? (
                                            <><div className="card mt-3">
                                                <Accordion defaultActiveKey="0">
                                                    <div>
                                                        <Card>
                                                            <ContextAwareToggle
                                                                eventKey={`0`}
                                                                header={<React.Fragment>
                                                                    <b>Cash Flow Pattern</b>

                                                                </React.Fragment>}
                                                            ></ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={`0`}>
                                                                <Card.Body>
                                                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                        <div className="row">
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Month Year</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Highest Debit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Highest Credit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Lowest Debit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Lowest Credit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Debit Turnover</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Credit Turnover</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Net Turnover</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Highest Balance</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Lowest Balance</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Balance</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Last Date of Credit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Last Date of Debit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Most Frequent Balance Range</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Most Frequent Credit Transaction Range</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Most Frequent Debit Transaction Range</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Inflow To Outflow Rate</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Salary Amount</strong>
                                                                                            </th>                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {_.isEmpty(z?.monthlySummary) ? ("No Monthly Summary") : (
                                                                                            // console.log(financialAnalysisResponse?.CreditDetails)
                                                                                            z?.monthlySummary?.map(x => (
                                                                                                <tr>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.Month}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.HighestDebitAmount), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.HighestCreditAmount), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.LoanAmount} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.LowestDebit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.LowestCredit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.TotalDebit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.TotalCredit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.LoanBalance} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.MonthlyNetTurnover), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountOverdue} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.HighestMonthlyBalance), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.LowestMonthlyBalance), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.AverageMonthlyBalance), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.LastDateOfCredit}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.LastDateOfDebit}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountWrittenOff} */}
                                                                                                        {x.MostFrequentBalanceRange}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.MostFrequentCreditTransactionRange}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.MostFrequentDebitTransactionRange}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountWrittenOff} */}
                                                                                                        {x.InflowToOutflowRate.toFixed(2)}
                                                                                                    </td>

                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountWrittenOff} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.SalaryAmount), 2)}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <dl className="row">
                                                                                    <DetailItem
                                                                                        label="Total Months Analysed"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={z?.TotalMonthsAnalysed} />
                                                                                    <DetailItem
                                                                                        label="Total Credit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.TotalCreditTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Total Debit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.TotalDebitTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Net Turnover for the period"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={textFormatService.formatCurrency(Number(z?.NetTurnoverForThePeriod), 2)} />
                                                                                    <DetailItem
                                                                                        label="Total Salary"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.TotalSalary), 2)} />


                                                                                    <DetailItem
                                                                                        label="Average Monthly Credit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyCreditTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Monthly Debit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyDebitTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Monthly Net Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyNetTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Monthly Balance"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyBalance), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Salary"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageSalary), 2)} />

                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </div>
                                                </Accordion>
                                            </div></>
                                        ) : ("")}


                                    </div>

                                ))}
                            </div>
                        </div>

                        <div className="card no-b">
                            <div className="row">
                                {financialAnalysisResponse?.filter(y => y.statementType === "Guarantor").length < 1 ? ("") : financialAnalysisResponse?.filter(y => y.statementType === "Guarantor")?.map(z => (
                                    <div className="col-md-12">
                                        <div className="card">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Guarantor Financial Analysis Result</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-5">
                                                                            {/* <h6
                                                                            className="card-title"
                                                                            style={{ marginBottom: "0px", fontFamily: "sans-serif" }}
                                                                        >
                                                                            <b>Performance Summary</b>
                                                                        </h6>
                                                                        <br /> */}
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Bank Name"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={z?.bankName}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Account Number"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={z?.accountNumber}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Account Type"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={z?.accountType}
                                                                                />
                                                                                {z?.channel === "MBS" ? (
                                                                                    <DetailItem
                                                                                        label="Status Message"
                                                                                        labelSize={9}
                                                                                        valueSize={5}
                                                                                        value={z?.statusMessage}
                                                                                    />
                                                                                ) : ("")}
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>

                                        <div className="card">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Spend Pattern</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-6">
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Expense Summary</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Date</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Category</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {expenseSummary.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.ExpenseSummary}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.Amount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Date}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Category}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <dl className="row mt-4">
                                                                                <DetailItem
                                                                                    label="Most Frequent Spend Category"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.MostFrequentSpendCategory}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Most Frequent Expense"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.MostFrequentExpense}
                                                                                />
                                                                                {/* <DetailItem
                                                                                    label="ATM Transaction Locations"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.ATMTransactionLocations}
                                                                                /> */}
                                                                            </dl>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Spend Category Summary</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {spendCategorySummary.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.SpendCategorySummary}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.Amount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.Average), 2)}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>
                                        <div className="card mt-3">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Transactional Pattern</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-12">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Total Transactions in the period"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.TotalTransactions}
                                                                                />
                                                                                {/* <DetailItem
                                                                                    label="Return Cheques"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={"0"}
                                                                                /> */}
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Debit Transactions (%)"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.DebitTransactionsPercentage}
                                                                                />
                                                                            </dl>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Recurring Debit Transactions</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {recurringDebitTransactions.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.RecurringDebitTransactions}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.AverageAmount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0 mt-3">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Debit Transactions' Analysis</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Percentage (%)</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {debitTransactionsAnalysis.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.DebitTransactionsAnalysis}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Percentage}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-6 ">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Credit Transactions (%)"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.CreditTransactionPercentage}
                                                                                />
                                                                            </dl>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Recurring Credit Transactions</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Amount</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {recurringCreditTransactions.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.RecurringCreditTransactions}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {textFormatService.formatCurrency(Number(x.AverageAmount), 2)}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0 mt-3">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Credit Transactions' Analysis</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Percentage (%)</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {creditTransactionsAnalysis.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.CreditTransactionsAnalysis}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Percentage}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>

                                        <div className="card mt-3">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>
                                                                <b>Behavioural Pattern</b>

                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">

                                                                        <div className="col-md-12">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Average Account Activity within the period analyzed"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.AverageAccountActivity}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Account Sweep"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.AccountSweep}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Gambling Activity"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.GamblingActivity}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Total Monthly Loan Repayment"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.TotalMonthlyLoanRepayment}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Monthly Inflow to Outflow Rate"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.AverageMonthlyInflowToOutflow}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Total Inflow to Outflow rate"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.TotalInflowToOutflow}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Balance Consistency"
                                                                                    labelSize={9}
                                                                                    valueSize={7}
                                                                                    // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                    value={financialAnalysisResponse?.analysis?.BalanceConsistency}
                                                                                />
                                                                                <DetailItem
                                                                                    label="Inflow Consistency"
                                                                                    labelSize={9}
                                                                                    valueSize={5}
                                                                                    value={financialAnalysisResponse?.analysis?.InflowConsistency}
                                                                                />
                                                                            </dl>
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Balance Analysis</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Percentage</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Frequency</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Monthly Frequency</strong>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {balanceAnalysis.map(x => (
                                                                                            <tr>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.BalanceAnalysis}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Percentage}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.Frequency}
                                                                                                </td>
                                                                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                    {x.AverageMonthlyFrequency}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>

                                        {z?.status === "Completed" || _.isNull(z?.status) ? (
                                            <><div className="card mt-3">
                                                <Accordion defaultActiveKey="0">
                                                    <div>
                                                        <Card>
                                                            <ContextAwareToggle
                                                                eventKey={`0`}
                                                                header={<React.Fragment>
                                                                    <b>Cash Flow Pattern</b>

                                                                </React.Fragment>}
                                                            ></ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={`0`}>
                                                                <Card.Body>
                                                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                        <div className="row">
                                                                            <div
                                                                                className="table-responsive text-nowrap"
                                                                                style={{
                                                                                    // height: "300px",
                                                                                    overflowY: "scroll",
                                                                                }}
                                                                            >
                                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Month Year</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Highest Debit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Highest Credit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Lowest Debit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Lowest Credit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Debit Turnover</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Credit Turnover</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Net Turnover</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Highest Balance</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Lowest Balance</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Average Balance</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Last Date of Credit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Last Date of Debit</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Most Frequent Balance Range</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Most Frequent Credit Transaction Range</strong>
                                                                                            </th>

                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Most Frequent Debit Transaction Range</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Inflow To Outflow Rate</strong>
                                                                                            </th>
                                                                                            <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                                                                <strong>Salary Amount</strong>
                                                                                            </th>                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {_.isEmpty(z?.monthlySummary) ? ("No loan history found") : (
                                                                                            // console.log(financialAnalysisResponse?.CreditDetails)
                                                                                            z?.monthlySummary?.map(x => (
                                                                                                <tr>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.Month}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.HighestDebitAmount), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.HighestCreditAmount), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.LoanAmount} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.LowestDebit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.LowestCredit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.TotalDebit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.TotalCredit), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.LoanBalance} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.MonthlyNetTurnover), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountOverdue} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.HighestMonthlyBalance), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.LowestMonthlyBalance), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {textFormatService.formatCurrency(Number(x.AverageMonthlyBalance), 2)}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.LastDateOfCredit}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.LastDateOfDebit}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountWrittenOff} */}
                                                                                                        {x.MostFrequentBalanceRange}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.MostFrequentCreditTransactionRange}
                                                                                                    </td>

                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {x.MostFrequentDebitTransactionRange}
                                                                                                    </td>
                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountWrittenOff} */}
                                                                                                        {x.InflowToOutflowRate.toFixed(2)}
                                                                                                    </td>

                                                                                                    <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                                                        {/* {x.AmountWrittenOff} */}
                                                                                                        {textFormatService.formatCurrency(Number(x.SalaryAmount), 2)}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div className="col-md-6 mt-3">
                                                                                <dl className="row">
                                                                                    <DetailItem
                                                                                        label="Total Months Analysed"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={z?.TotalMonthsAnalysed} />
                                                                                    <DetailItem
                                                                                        label="Total Credit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.TotalCreditTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Total Debit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.TotalDebitTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Net Turnover for the period"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={textFormatService.formatCurrency(Number(z?.NetTurnoverForThePeriod), 2)} />
                                                                                    <DetailItem
                                                                                        label="Total Salary"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.TotalSalary), 2)} />
                                                                                    {/* <DetailItem
        label="Total Other Income"
        labelSize={9}
        valueSize={3}
        value={z?.TotalOtherIncome}
    /> */}
                                                                                    <DetailItem
                                                                                        label="Average Monthly Credit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyCreditTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Monthly Debit Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyDebitTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Monthly Net Turnover"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyNetTurnover), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Monthly Balance"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageMonthlyBalance), 2)} />
                                                                                    <DetailItem
                                                                                        label="Average Salary"
                                                                                        labelSize={9}
                                                                                        valueSize={3}
                                                                                        value={textFormatService.formatCurrency(Number(z?.AverageSalary), 2)} />
                                                                                    {/* <DetailItem
        label="Average Other Income"
        labelSize={9}
        valueSize={3}
        value={z?.AverageOtherIncome}
    /> */}
                                                                                </dl>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </div>
                                                </Accordion>
                                            </div>
                                            </>
                                        ) : ("")}


                                    </div>
                                ))}

                            </div>
                        </div>

                        <div className="card-no-b">
                            <div className="row">
                                {financialAnalysisResponse?.filter(y => y.statementType === "Guarantor").length === 0 ? ("") : (
                                    <div className="col-md-12">
                                        <div className="card">
                                            <Accordion defaultActiveKey="0">
                                                <div>
                                                    <Card>
                                                        <ContextAwareToggle
                                                            eventKey={`0`}
                                                            header={<React.Fragment>

                                                                <b>Guarantor Eligibility Check</b>
                                                            </React.Fragment>}
                                                        ></ContextAwareToggle>
                                                        <Accordion.Collapse eventKey={`0`}>
                                                            <Card.Body>
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Total Loan Obligation"
                                                                                    labelSize={9}
                                                                                    valueSize={3}
                                                                                    value={textFormatService.formatCurrency(totalLoanObligation, 2)}
                                                                                />

                                                                                <DetailItem
                                                                                    label="Guarantor Net Turnover"
                                                                                    labelSize={9}
                                                                                    valueSize={3}
                                                                                    value={textFormatService.formatCurrency(guarantorTurnOver, 2)}
                                                                                />

                                                                                <DetailItem
                                                                                    label="Eligibility Check (%)"
                                                                                    labelSize={9}
                                                                                    valueSize={3}
                                                                                    value={guarantorTurnOver === 0 ? 0.00 : ((totalLoanObligation / guarantorTurnOver) * 100).toFixed(2)}
                                                                                />
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </div>
                                            </Accordion>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default FinancialAnalysisCheck;
